import React from "react";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { useLocation, Link } from "react-router-dom";
import Header from "./../assets/header-fipe.png";
import FooterProposta from "../components/Footer/FooterProposta";
import Accordion from "./../components/Servicos/Accordion";
import Valor from "../components/Valor";
import { IoArrowBackCircle } from "react-icons/io5";
import Form2 from "../components/Form2";

const Proposta = () => {
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
    ReactPixel.trackCustom("Lead"); // For tracking custom'Cotacao2'); // For tracking custom . More info about custom : https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-

  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

  return (
    <div className="relative w-screen bg-gray-400">
      <div className="md:flex">
        <div className="p-4 lg:flex-row md:w-1/2">
          <div className="flex-row mb-2 p-2 bg-gray-500 rounded-md shadow-md">
            <Link to="/">
              <button className="bg-slate-500 text-slate-50 rounded-full  flex">
                <IoArrowBackCircle className="text-3xl font-bold flex-space " />
                <h1 className="text-xl flex-space text-center pl-2">Voltar</h1>
              </button>
            </Link>
          </div>
          <div className="bg-black lg:mt-4 shadow-md overflow-hidden rounded-lg text-center">
            <img
              className="w-full"
              alt="Seja um associado"
              src={Header}
            />

            <div className="px-4 pb-3 sm:px-6">
              <h3 className="text-xl  text-center leading-6 font-medium text-slate-50">
                Dados do seu caminhão
              </h3>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-slate-200 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-lg font-medium text-gray-500">Marca</dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0 sm:col-span-2">
                    {state.formValues.Marca}
                  </dd>
                </div>
                <div className="bg-slate-100 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-lg font-medium text-gray-500">Modelo</dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0 sm:col-span-2">
                    {state.formValues.Modelo}
                  </dd>
                </div>
                <div className="bg-slate-200 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-lg font-medium text-gray-500">Ano</dt>
                  <dd className="mt-1 text-lg text-gray-900 sm:mt-0 sm:col-span-2">
                    {state.formValues.AnoModelo === 32000
                      ? "Zero"
                      : state.formValues.AnoModelo}
                  </dd>
                </div>
                <div className="bg-slate-100 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-lg font-medium text-gray-500">
                    Valor Tabela FIPE
                  </dt>
                  <dd className="mt-1 text-2xl font-bold text-red-500 sm:mt-0 sm:col-span-2">
                    {state.formValues.Valor}
                  </dd>
                </div>
              </dl>
            </div>
          </div>{" "}
          <div className="pt-4 flex lg:justify-center ">
            <a
              className="inline-flex shadow-md justify-center p-8 py-4 px-10 md:px-20 border border-transparent text-sm font-medium text-center rounded-full text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-full lg:w-4/5"
              href="https://wa.me/5544998030738?text=Ol%C3%A1%2C+tenho+interesse+em+saber+um+pouco+mais%21"
              onClick={scrollToTop}
            >
              QUERO FALAR COM UM CONSULTOR!
            </a>
          </div>
        </div>
        <div className="md:w-1/2 ">
          <Accordion />
        <Form2 /></div>
        
      </div>

      <FooterProposta className="lg:fixed" />
      <Valor />
    </div>
  );
};

export default Proposta;
